@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;800&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --text-dark-background: #fff;
  --text-light-background: #3a3a3aea;
  --profile_section_text_container_height: 400px;
  --bg-1: #eef2f7;
  --bg-2: #f3f0ed;
  --site-primary-color: #36607e;
  --site-secondary-color: #f3f0ed;
  --site-alternate-color: #87b0b7;
  --site-alternate-color-2: #60c3bb;
  --site-alternate-color-3: #b3f5f9;
  --nav-bg: rgba(179, 245, 249, 0.8);
  --hoverCardDarken: rgba(0, 0, 0, 0.4);

  --button-linear-gradient-color-1: #583d26;
  --button-linear-gradient-color-2: #7e5736;
  --button-linear-gradient-color-3: #264c58;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.section-title {
  font-weight: 600;
  color: black;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  letter-spacing: 3px;
}

.card-bg-1 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-color-1 {
  background-color: var(--site-primary-color);
}

.bg-color-2 {
  background-color: var(--site-secondary-color);
}

.bg-color-3 {
  background-color: var(--site-alternate-color);
}

.bg-color-4 {
  background-color: var(--bg-1);
}

.redText {
  color: red;
}

.gap {
  gap: 0.5rem;
}

.width-max {
  width: max-content;
}

.width-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.width-75 {
  width: 75% !important;
}
.width-50 {
  width: 50% !important;
}
.width-25 {
  width: 25% !important;
}

.min-height-500 {
  min-height: 500px;
}

.min-height-800 {
  min-height: 800px;
}

.height-100 {
  height: 100% !important;
}

.height-75 {
  height: 75% !important;
}
.height-50 {
  height: 50% !important;
}
.height-25 {
  height: 25% !important;
}

.height-min {
  height: min-content;
  max-height: max-content;
}

.height-max {
  height: max-content;
}

.overflow-y-hide {
  overflow-y: hidden;
}

.overflow-y-show {
  overflow-y: auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-d-col {
  flex-direction: column;
}

.flex-shrink-3 {
  flex-shrink: 3;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-1 {
  flex-shrink: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-shrink-3 {
  flex-shrink: 3;
}

.grid {
  display: grid !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-inline-sm {
  margin: 0.5rem 0;
}

.margin-inline-sm {
  margin: 2rem 0;
}

.margin-inline-sm {
  margin: 4rem 0;
}

.padding-inline-sm {
  padding: 0 1rem;
}

.padding-inline-md {
  padding: 0 2rem;
}

.padding-inline-lg {
  padding: 0 4rem;
}

.padding-tb-sm {
  padding: 1rem 0;
}

.padding-b-sm {
  padding-bottom: 1rem;
}

.padding-b-md {
  padding-bottom: 2rem;
}

.padding-0 {
  padding: 0 !important;
}

.padding-sm {
  padding: 1rem;
}

.padding-md {
  padding: 2rem;
}

.padding-lg {
  padding: 4rem;
}

.grid-col-1 {
  grid-template-columns: auto;
}

.grid-col-repeat-auto {
  grid-template-columns: repeat(6, auto);
}

.grid-mobile-nav {
  grid-template-columns: 75% auto;
}

.grid-mobile-nav > :first-child {
  justify-content: start;
  padding-left: 5rem;
}

.grid-col-repeat-3-auto {
  grid-template-columns: repeat(3, 2fr);
}

.grid-col-repeat-3-auto > :last-child {
  grid-column-start: 2;
  grid-column-end: 3;
}

.grid-col-30-repeat-auto {
  grid-template-columns: 30% repeat(6, auto);
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}
.align-items-start {
  align-items: start;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

#homeLogo {
  object-fit: cover;
  aspect-ratio: 1/1;
  width: 50px;
  transform: scale(3);
  padding-top: 5px;
}

#homeLogo:hover {
  filter: invert(100%) sepia(100%) saturate(0%) brightness(50%) contrast(200%);
  transition: all;
  animation: animateLogo 8.5s linear infinite;
}

@keyframes animateLogo {
  0%,
  100% {
    filter: invert(0%) sepia(0%) saturate(0%) brightness(100%) contrast(200%);
    /* filter: invert(48%) sepia(85%) saturate(2500%) hue-rotate(130deg)
      brightness(95%) contrast(120%); */
  }

  50% {
    filter: invert(100%) sepia(100%) saturate(0%) brightness(50%) contrast(200%);
    /* filter: invert(85%) sepia(30%) saturate(1207%) hue-rotate(130deg)
      brightness(95%) contrast(80%); */
  }
}

.nav {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  padding: 0.8rem 0;
  height: 90px;
  top: 0;
  left: 0;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0;
  backdrop-filter: blur(5px);
}

.nav-item > a {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.nav-item > a:hover,
.nav-item > a:focus {
  --bg-size: 400%;
  --color-one: var(--site-alternate-color);
  --color-two: var(--site-alternate-color-2);
  --color-three: var(--site-primary-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-three),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: colorChange 8.5s infinite linear;
}

.nav-item {
  padding-block: 0.5rem;
  margin: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-mobile-menu-icon {
  transform: scale(0.5);
}

.nav-mobile-menu-icon-background {
  background: linear-gradient(
    to right,
    var(--site-alternate-color-2),
    var(--site-alternate-color),
    var(--site-primary-color)
  );
  transform: scale(0.5);
  z-index: 9998;
  box-shadow: 1px 2px 3px 1px black;
  cursor: pointer;
}

.nav-mobile-menu {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  right: 0;
  height: 600px;
  overflow-y: scroll;
  width: max-content !important;
  z-index: 999;
  box-shadow: -0.1rem 0.1rem 5px 2px rgb(121, 109, 109);
  border-top: 1px solid black;
  border-right: 1px solid black;
  display: grid;
  justify-content: center;
  justify-items: left;
  grid-template-rows: 30% auto;
  grid-template-columns: 100%;
  gap: 0.5rem;
  opacity: 1;
  transition: all 1s;
  border-bottom-left-radius: 5px;
}

.nav-mobile-menu > :first-child {
  grid-row-start: 2;
  grid-row-end: 3;
}

.nav-mobile-menu > :first-child div {
  width: auto;
}

.nav-mobile-menu > div > div > a {
  color: black;
  font-weight: 500;
  font-size: 16px;
  width: fit-content !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

main {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.main::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.parallax-text {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  font-size: 48px;
  text-align: center;
  /* rgba(51, 51, 51, 0.7) */
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 1rem;
  z-index: 2;
}

.parallax-call-to-action {
  text-decoration: none;
  color: #fff;
  font-size: 28px;
}

.parallax-call-to-action:hover {
  color: #b3f5f9;
  font-size: 32px;
  text-shadow: 1px 1px 2px black;
}

.moon-img {
  height: 175px;
  aspect-ratio: 1/1;
  padding: 0.5rem;
  filter: brightness(100%);
  animation: glow 7s linear infinite;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.visible-flex {
  display: flex;
}

.visible-grid {
  display: grid;
}

.spacer-lg {
  padding: 200px;
}

.background,
.foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(1.5px);
}

.background {
  transform: translateZ(-10px) scale(2);
}

.foreground {
  transform: translateZ(-5px) scale(1.5);
}

.front-img {
  top: calc(40% - 200px);
  z-index: -2;
}

.front-img2 {
  top: calc(45% - 50px);
  z-index: -3;
}

.mountain {
  top: 10%;
  left: 0;
}

.bg-img02 {
  top: -4%;
}

.home-img-mobile {
  height: clamp(50vh, 75vh);
  width: 100vw;
}

.profile_text {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  padding: 1.25rem;
  font-size: large;
  border-radius: 5px;
  width: 85%;
  overflow: hidden;
  box-shadow: 3px 3px 5px 1px black;
  background: var(--site-secondary-color);
  color: var(--text-light-background);
}

.profile_text span {
  z-index: 5;
  position: inherit;
  /* padding: 0.5rem; */
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-dark-background {
  color: var(--text-dark-background) !important;
}

.text-light-background {
  color: var(--text-light-background) !important;
}

.profile_section {
  margin-top: 2rem;
  background-color: var(--bg-1);
}

.minimized {
  height: 30%;
  overflow-y: hidden;
  transition: all;
  animation: minimize linear 1s;
}

.expanded {
  height: 100%;
  overflow-y: hidden;
  transition: all;
  animation: expand linear 1s;
}

#expandAboutMe {
  margin-bottom: 1rem;
}

.profile_section_minimized {
  height: var(--profile_section_text_container_height);
  transition: all;
  animation: minimize_profile_container 1s ease-in;
}

.profile_section_expanded {
  height: auto;
  transition: all;
  animation: expand_profile_container 1s ease-in;
}

@media (prefers-reduced-motion: no-preference) {
  @supports (animation-timeline: scroll()) {
    .mobileFadeIn {
      position: relative;
      top: 0;
      left: 0;
      animation: mobileFadeIn linear both;
    }

    .mobileScrollInSideLeft {
      animation: mobileScrollInSideLeft linear both;
      position: relative;
      top: 0;
      left: 0;
    }

    .z-mobileFadeIn {
      animation-timeline: view();
      animation-range: entry 30% cover 40%;
    }
  }
}

.services-section {
  background-color: var(--bg-2);
}

.servicesCard {
  cursor: pointer;
  --_shadow: var(--shadow, transparent);
  --_bottom-color: var(--bottom-color, black);
  --_fontWeight: var(--font-weight-header, 400);
  --_fontWeight-text: var(--font-weight-text, 400);
  --_background-color: var(--background-color, var(--bg-1));
  --font-weight-header: 600;
  --font-weight-text: 500;

  background: var(--_background-color) !important;
  margin-bottom: 0.5rem;
  height: clamp(500px, 570px, 589px) !important;
  width: 400px;
  min-width: 50%;
  padding: 1.25rem;
  text-align: center;
  --_border-color: var(--border-color, rgba(52, 125, 193, 0.3));
  border-left: 2px solid var(--_border-color) !important;
  border-top: 2px solid var(--_border-color) !important;
  filter: blur(0.02rem);
  box-shadow: -0.5rem -0.5rem 5px -1.5px var(--_shadow);
  border-bottom: 4px solid var(--_bottom-color) !important;
  animation-fill-mode: both;
}

.servicesCard:hover {
  z-index: 3;
  box-shadow: 10px 10px 10px #7a7a7a7a,
    -0.5rem -0.5rem 5px -1.5px var(--_shadow);
  transform: scale(1.05);
  filter: blur(0);
  --background-color: rgba(255, 255, 255, 0.7);
}

.blur-card {
  filter: blur(
    3px
  ); /* Apply the blur effect to other cards when one is hovered */
}

.servicesCard--frontEndDev {
  /*
  96, 195, 187, 0.3
  var(--site-alternate-color-2)
  */
  /* --border-color: rgba(0, 0, 0); */
  --bottom-color: rgba(237, 188, 79);
}

.servicesCard--frontEndDev:hover {
  /* var(--site-alternate-color-2) */
  --shadow: rgba(233, 176, 83);
}

.servicesCard--backEndDev {
  /* --bottom-color: var(--site-alternate-color-3); */
  --border-color: #87b0b74d;
  --bottom-color: rgba(63, 110, 251);
}

.servicesCard--backEndDev:hover {
  /* --shadow: var(--site-alternate-color-3); */
  --shadow: rgba(63, 110, 251);
}

.servicesCard--fullStackDev {
  /* --border-color: rgba(135, 176, 183, 0.3);
  --bottom-color: var(--site-alternate-color); */
  /* --border-color: rgba(170, 154, 234); */
  --bottom-color: rgba(170, 154, 234);
}

.servicesCard--fullStackDev:hover {
  --shadow: rgba(104, 82, 175);
}

.servicesCard--backEndDev .servicesCardImage {
  transform: scaleX(-1);
}

.servicesCard--fullStackDev .servicesCardImage {
  transform: scaleX(-1);
}

.servicesCardImage {
  border: 2px solid #333;
  height: 250px;
  aspect-ratio: 1/1;
}

.servicesCard > div > .servicesCard-title {
  font-weight: var(--_fontWeight, 600) !important;
}

.servicesCard .servicesCard-text {
  font-weight: var(--_fontWeight-text, 100) !important;
}

.portfolio-section {
  background-color: var(--bg-1);
}

.skills-section {
  background-color: var(--bg-2);
}

.portfolioCard {
  position: relative;
  padding: 0.5rem;
  margin: 0.5rem;
  top: 0%;
  left: 0;
  --bs-card-height: 325px !important;
  aspect-ratio: 1.2/1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
  border-bottom: 2px solid #444343 !important;
}

.dropDownAnimation {
  top: 200%;
  animation: dropDown 2s ease-in-out;
  transition: all 2s;
}

.scrollInAnimation {
  top: 0%;
  transition: all 2s;
}

.portfolioCard:hover {
  animation: portfolioCardHover 750ms linear;
  box-shadow: 3px 3px 10px 1px rgb(104, 104, 104);
}

.portfolioCardImage {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  height: 75%;
  border-radius: 30px !important;
}

.hoverCard {
  position: absolute;
  height: 65%;
  width: 92%;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: var(--hoverCardDarken);
  border-radius: 10px;
  grid-template-columns: repeat(4, 50px); /* Four columns */
  grid-template-rows: 75px 75px; /* Two rows */
  align-items: center;
  justify-content: center;
  transition: all;
  gap: 20px;
  animation: darkenBackground 750ms linear;
}

.hoverCard-img-icon-text {
  padding: 0.5rem;
  border-radius: 10px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 1);
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  grid-column: 1 / span 4;
}

.portfolioCard-text {
  font-weight: 600;
}

.portfolioCard-body {
  height: 20%;
  width: 100%;
  position: absolute;
  text-align: center;
  top: 70%;
  left: 0;

  z-index: 3;
}

.portfolioCardDevStack {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.8rem;
  border-radius: 10px;
  z-index: 1;
}

.portfolio-button-container {
  display: grid;
  grid-template-columns: repeat(4, 130px);
  gap: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.portfolio-button-container button,
#expandAboutMe {
  background-color: var(--bg-2) !important;
  --_borderColor: var(--borderColor, black);
  color: #444343;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem;
  border: 2px solid var(--_borderColor) !important;
}

.portfolio-button-container button:hover,
.portfolio-button-container button:focus,
#expandAboutMe:hover {
  --borderColor: var(--site-alternate-color);
  color: var(--site-alternate-color);
  box-shadow: 3px 3px 5px black;
}

.portfolioCard-img-icon {
  height: 50px;
  aspect-ratio: 1/1;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem;
}

.portfolioCard-img-link:nth-child(2) {
  grid-column: 2;
  grid-row: 2;
}

.portfolioCard-img-link:nth-child(3) {
  grid-column: 3;
  grid-row: 2;
}

.cardDetail {
  /* position: absolute;
  top: 55%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  padding: 2rem;
  background-color: var(--bg-2);
  text-decoration: none;
  margin: 9rem 0;
  padding-bottom: 2rem;
  overflow-y: scroll;
}

.details-button-home-contact-form,
.details-button-home,
.site-link {
  width: max-content;
  color: var(--site-primary-color);
  font-weight: 600;
  cursor: pointer;
}

.details-button-home-contact-form:hover,
.details-button-home:hover,
.site-link:hover {
  color: var(--site-alternate-color-2);
  transform: scale(1.1);
}

.cardDetail div h2 {
  font-weight: 700;
  color: var(--site-primary-color);
}

.cardDetail div div h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--site-primary-color);
}
.cardDetail div div div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.cardDetail .techUsedItem li {
  width: fit-content;
  position: relative;
  z-index: 1;
  padding: 0.3rem !important;
  margin: 1.25rem;
  border-radius: 15px !important;
  background: transparent;
  color: black;
  border: none;
}

.cardDetail .techUsedItem li::before,
.cardDetail .techUsedItem li::after {
  content: "";
  position: absolute;
  inset: -0.5rem;
  z-index: -1;
  border: 1px solid black !important;
  border-left: 1px solid white !important;
  border-top: 1px solid white !important;
  background: conic-gradient(
    from var(--gradient-angle),
    white,
    whitesmoke,
    rgba(54, 96, 126, 0.3),
    transparent,
    transparent,
    rgba(54, 96, 126, 0.3),
    whitesmoke,
    white
  );
  box-shadow: 2px 1px 5px 1px black, 4px 4px 10px 1px rgba(54, 96, 126, 0.3);
  border-radius: inherit;
  animation: rotation 20s linear infinite;
}

.cardDetail .techUsedItem li::after {
  filter: blur(1rem);
}

.cardDetail p {
  font-weight: 500;
  font-size: large;
  color: #444343;
}

.cardDetail div div a {
  text-decoration: none;
  font-weight: 600;
  padding: 0 0.25rem;
}

.icon {
  transform: scale(1);
  fill: black;
}

.img-icon {
  --_background-color: var(--background-color, rgba(0, 0, 0, 0));
  aspect-ratio: 1/1;
  height: 100px;
  border-radius: 50%;
  background-color: var(--_background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.vingette {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 65%,
    rgba(0, 0, 0, 0.7)
  );
}

.footer-section {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.footer-icon,
.footer-icon-inner {
  cursor: pointer;
  aspect-ratio: 1/1;
  height: 75px;
  --_fill: var(--fill, black);
  fill: var(--_fill);
}

.instagram:hover {
  --fill: #cd486b;
  fill: currentColor;
  animation: instagram 10s linear infinite;
  animation-fill-mode: forwards;
}

.github:hover {
  --fill: #4078c0;
}

.linkedin:hover {
  --fill: #0077b5;
}

.footer-text-section {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.contact-form-section {
  margin: 10rem auto 2rem;
  width: 95% !important;
  background-color: var(--bg-1);
}

textarea {
  resize: none;
}

div [role="alert"] {
  width: 95% !important;
  margin: 0 auto;
}

.appearAnimation {
  animation: appear 1.5s forwards ease;
  transition: all;
}

.disappearAnimation {
  animation: disappear 1.5s forwards ease;
  transition: all;
}

.slideInToLeftAnimation {
  animation: slideInToLeft 1.5s forwards ease;
  transition: all;
}

.slideOutToRightAnimation {
  animation: slideOutToRight 1s forwards ease-in;
  transition: all;
}

.dropDownFadeAwayAnimation {
  animation: FadeAwayAnimation 4s ease-in-out;
  transition: all 2s ease;
}

.growServiceCardAnimation {
  animation: grow 1s ease both;
  transition: all;
}

.shrinkServiceCardAnimation {
  animation: shrink 1s ease both;
  transition: all;
}

@keyframes portfolioCardHover {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 3px 3px 10px 1px rgb(104, 104, 104);
  }
}

@keyframes instagram {
  0%,
  100% {
    fill: #fbad50;
  }
  40%,
  80% {
    fill: #cd486b;
  }
}

@keyframes mobileFadeIn {
  from {
    top: 10%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes mobileScrollInSideLeft {
  from {
    opacity: 0;
    left: -100%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes colorChange {
  to {
    background-position: var(--bg-size) 0;
  }
}

@keyframes glow {
  0%,
  100% {
    filter: brightness(70%);
  }
  50% {
    filter: brightness(110%);
  }
}

@keyframes dropDown {
  0% {
    top: 200% !important;
  }

  100% {
    top: 0% !important;
  }
}

@keyframes FadeAwayAnimation {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInToLeft {
  0% {
    right: -50%;
  }
  80% {
    right: 0.5%;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOutToRight {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes darkenBackground {
  from {
    background: transparent;
  }
  to {
    background-color: var(--hoverCardDarken);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
    background-color: var(--bg-1);
    box-shadow: none;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 12px 12px 25px 2px #7a7a7a7a,
      -0.5rem -0.5rem 5px -1.5px var(--_shadow);
  }
  75% {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px #7a7a7a7a,
      -0.5rem -0.5rem 5px -1.5px var(--_shadow);
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}

@keyframes shrink {
  0% {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px #7a7a7a7a,
      -0.5rem -0.5rem 5px -1.5px var(--_shadow);
    background-color: rgba(255, 255, 255, 0.7) !important;
  }

  100% {
    transform: scale(1);
    box-shadow: -0.5rem -0.5rem 5px -1.5px var(--_shadow);
    background-color: var(--bg-1) !important;
  }
}

@keyframes expand {
  from {
    height: 30%;
  }
  to {
    height: 100%;
  }
}

@keyframes minimize {
  from {
    height: 100%;
  }
  to {
    height: 30%;
  }
}

@keyframes expand_profile_container {
  from {
    height: 500px;
  }
  to {
    height: 100%;
  }
}

@keyframes minimize_profile_container {
  from {
    height: 100%;
  }
  to {
    height: 500px;
  }
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1086px) {
  [data-rr-ui-event-key="link-6"] {
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .nav {
    height: unset;
  }
  .portfolio-button-container {
    grid-template-columns: repeat(3, 9rem);
  }
  .portfolio-button-container button {
    padding: 1rem 1rem;
  }
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .portfolio-button-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardDetail {
    position: unset;
    transform: translate(0, 0);
    margin: 8rem 0;
  }

  .cardDetail div {
    flex-direction: column;
  }

  .grid-col-repeat-3-auto {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
  .grid-col-repeat-3-auto > :last-child {
    grid-column-start: unset;
    grid-column-end: unset;
  }

  .grid-col-repeat-auto {
    grid-template-columns: auto;
    align-items: center;
    justify-content: center !important;
  }
}

/* Media query for screens smaller than 480px */
@media screen and (max-width: 480px) {
  .nav-mobile-menu-icon-background {
    transform: scale(0.4);
  }

  .portfolio-button-container {
    grid-template-columns: repeat(1, 1fr); /* Two equal-width columns */
  }

  .nav-mobile-menu {
    height: 375px;
  }

  #homeLogo {
    transform: scale(2.2);
  }

  .nav-item {
    padding-block: unset;
  }

  .grid-mobile-nav {
    grid-template-columns: 70% auto;
  }

  .img-icon {
    height: 75px;
  }

  .grid-mobile-nav > :first-child {
    padding-left: 0.5rem;
  }

  .padding-md {
    padding: 1rem;
  }

  .text {
    padding: 0.8rem;
    font-size: clamp(medium, 1rem, 2%vh);
    width: 100%;
  }

  .minimized {
    height: 100%;
    overflow: scroll;
  }

  .profile_section_minimized {
    height: fit-content;
  }

  .footer-icon {
    transform: scale(0.9);
  }

  @media screen and (max-width: 350px) {
    .servicesCard {
      height: fit-content !important;
    }
  }
}
